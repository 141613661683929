/* Here an example for your custom css
----------------------------------------------------------------------------------------*/

#root {
    position: relative;
    min-height: 100vh;
}

body {
    background-color: white;
    color: white;
    -moz-font-feature-settings: 'liga'on;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    text-rendering: optimizeLegibility;
}

/* Fonts
----------------------------------------------------------------------------------------*/

html, body {
    line-height: 1.2;
    font-family: "poppins",sans-serif;
    font-weight: 400;
}
* {
    font-family: Lato,PingFang SC,Microsoft YaHei,Helvetica,Arial,sans-serif;
}

.font-inter-100 {
    font-family: 'Inter', sans-serif;
    font-weight: 100;
}

.font-inter-300 {
    font-family: 'Inter', sans-serif;
    font-weight: 300;
}

.font-inter-400 {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}

.font-inter-500 {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
}

.sensia-table thead tr th,
.report-table thead, .font-inter-600 {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
}

.font-inter-700 {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
}

.central-content-1000 {
    position: relative;
    max-width: 1000px;
    margin: 0 auto;
}

.central-content-1000.no-responsive {
    width: 1000px;
}

@media only screen and (max-width: 1040px) {
    .central-content-1000:not(.no-responsive-margin) {
        margin: 0 auto;
    }
}

@media only screen and (max-width: 1030px) {
    .central-content-1000:not(.no-responsive-margin) {
        margin: 0 15px;
    }
}

.central-content-1200 {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
}

.central-content-1200.no-responsive {
    width: 1200px;
}

@media only screen and (max-width: 1240px) {
    .central-content-1200:not(.no-responsive-margin) {
        margin: 0 auto;
    }
}

@media only screen and (max-width: 1230px) {
    .central-content-1200:not(.no-responsive-margin) {
        margin: 0 15px;
    }
}

.central-content-1400 {
    position: relative;
    max-width: 1400px;
    margin: 0 auto;
}

.central-content-1400.no-responsive {
    width: 1400px;
}

@media only screen and (max-width: 1440px) {
    .central-content-1400:not(.no-responsive-margin) {
        margin: 0 auto;
    }
}

@media only screen and (max-width: 1430px) {
    .central-content-1400:not(.no-responsive-margin) {
        margin: 0 15px;
    }
}

.pricing-showcase .container-pricing-box .column-percent-34,
.pricing-showcase .container-pricing-box .column-percent-50 {
    text-align: center;
}

.pricing-showcase .container-pricing-box .column-percent-50:first-child {
    padding-right: 10px;
}
.pricing-showcase .container-pricing-box .column-percent-50:last-child {
    padding-left: 10px;
}
.pricing-showcase .container-pricing-box .column-percent-50 .content-pricing-box,
.pricing-showcase .container-pricing-box .column-percent-100 .content-pricing-box {
    width: 100%;
}


.button {
    line-height: 34px;
    border-radius: 6px;
    font-size: 17px;
    font-weight: bold;
    text-align: center;
    position: relative;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    outline: 0;
    padding: 0 12px;
    text-decoration: none;
    display: inline-block;
}

.button.width-170 {
    width: 170px;
}

.button.height-45 {
    height: 45px;
    line-height: 45px;
}

.button:disabled {
    opacity: 0.5;
    cursor: default;
}

.loading-form .button, .loading-form .button:disabled {
    opacity: 1;
    color: transparent!important;
    overflow: hidden;
}

.button {
    background-color: white;
    -webkit-transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
    -moz-transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
    -o-transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
    -ms-transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.button:not(:disabled):hover {
    background-color: #39325F;
}

.link-multi-state {
    position: relative;
    text-decoration: none;
    cursor: pointer;
}

.link-multi-state:hover .initial, .link-multi-state .on-hover {
    display: none;
}

.link-multi-state:hover .on-hover {
    display: block;
}

.link-blue {
    text-decoration: none;
    color: #3a79e6;
    cursor: pointer;
}

.link-blue:hover {
    text-decoration: none;
    color: #0075ff;
}

.link-grey {
    text-decoration: none;
    color: #7f7f7f;
    cursor: pointer;
}

.link-grey:hover {
    text-decoration: none;
    color: #535353;
}

.button.light-blue {
    background-color: #e2ebfd;
    color: #3a79e6;
}

.button.light-blue:not(:disabled):hover {
    background-color: #cde3fe;
}


.button.backgroundless {
    background-color: transparent!important;
    color: #3a79e6;
    font-size: 17px;
    font-weight: 500;
}

.button.backgroundless.font-weight-600 {
    font-weight: 600;
}

.button.backgroundless:not(:disabled):hover {
    color: #0075ff;
}

.button.backgroundless.pink {
    color: #e04f77;
}

.button.backgroundless.grey {
    color: #969696;
}

.button.backgroundless.grey:hover {
    color: #747474;
}

.button.backgroundless:disabled {
    color: #969696!important;
}