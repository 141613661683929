html,
body {
  min-height: 100vh;
  background-color: #f1f1f8;
}

body {
  max-width: 100%;
  overflow-x: hidden;
}

/* //////////////////////////////////////////////////////////////////////// */

.article-body .head-article {
  margin-left: -5px;
}

.font-back {
  transform: skew(-20deg);
  overflow: hidden;
  width: 100%;
  /* box-shadow: 2px 0 0 #000, 2px 2px 0 #000, 0 2px 0 #000, -2px 2px 0 #000,
    -2px 0 0 #000, -2px -2px 0 #000, 0 -2px 0 #000, 2px -2px 0 #000; */
}

.font-back.NFT {
  background: #f18f01;
}
.font-back.Crypto {
  background: #44cf6c;
}
.font-back.Defi {
  background: #f5d400;
}

.font-back .wrapper {
  transform: skew(20deg);
}

.font-back .logo-big {
  font-family: "Anton";
  transform: skew(-20deg);
  font-size: 110px;
  padding-left: 20px;
  color: #fff;
  text-shadow: 2.5px 0 0 #000, 2.5px 2.5px 0 #000, 0 2.5px 0 #000,
    -2.5px 2.5px 0 #000, -2.5px 0 0 #000, -2.5px -2.5px 0 #000, 0 -2.5px 0 #000,
    2.5px -2.5px 0 #000;
}

.font-back .meta-logo {
  width: 100px;
  height: 50px;
}

.main-content-article .link {
  text-decoration: none !important;
}

/* //////////////// READ TIME ; TITLE ; INTRO /////////////////////////////// */

.font-back .time-read {
  font-family: "Anton";
  font-size: 25px;
  font-style: italic;
  float: right;
  padding-right: 10px;
}

.font-back .content-read-published {
  padding-bottom: 40px;
  padding-top: 45px;
}

.font-back .published {
  font-family: "Anton";
  font-style: italic;
  font-weight: 700;
  font-size: 20px;
  float: left;
}

.font-back .time-read {
  font-family: "Anton";
  font-style: italic;
  font-weight: 700;
  font-size: 20px;
  float: right;
}

.font-back .title {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 50px;
  padding-top: 5px;
  /* text-shadow: 2px 0 0 #d60, 2px 2px 0 #d60, 0 2px 0 #d60, -2px 2px 0 #d60,
    -2px 0 0 #d60, -2px -2px 0 #d60, 0 -2px 0 #d60, 2px -2px 0 #d60; */
  /* text-shadow: 2px 0 0 #000, 2px 2px 0 #000, 0 2px 0 #000, -2px 2px 0 #000,
    -2px 0 0 #000, -2px -2px 0 #000, 0 -2px 0 #d60, 2px -2px 0 #000; */
}

.font-back .intro {
  /* text-shadow: 2px 0 0 #d60, 2px 2px 0 #d60, 0 2px 0 #d60, -2px 2px 0 #d60,
    -2px 0 0 #d60, -2px -2px 0 #d60, 0 -2px 0 #d60, 2px -2px 0 #d60; */
  /* text-shadow: 1px 0 0 #000, 1px 1px 0 #000, 0 1px 0 #000, -1px 1px 0 #000,
    -1px 0 0 #000, -1px -1px 0 #000, 0 -1px 0 #d60, 1px -1px 0 #000; */
  font-size: 28px;
  padding-top: 50px;
  padding-bottom: 300px;
  font-family: "Oswald", sans-serif;
}

.font-back .intro.Defi {
  color: #0a2463;
}
.font-back .intro.Crypto {
  color: #0a2463;
}
.font-back .intro.NFT {
  color: #0a2463;
}
.font-back .title.Defi {
  color: #0a2463;
}
.font-back .title.Crypto {
  color: #0a2463;
}
.font-back .title.NFT {
  color: #0a2463;
}

/* ///////// WRITED BY SECTION /////////////////////////////// */

.writed-by-section {
  padding-top: 260px;
  text-align: center;
}

.img-writed-by-article {
  border-radius: 50%;
  width: 80px;
}

.writed-by-section .published {
  font-size: 18px;
  font-family: "Anton";
  color: black;
  padding-top: 20px;
}

.writed-by-section .writed-by-title {
  font-size: 18px;
  font-family: "Anton";
  padding-top: 5px;
  color: black;
}

.writed-by-section-big {
  padding-top: 50px;
  padding-bottom: 100px;
  padding-left: 20px;
  display: flex;
}

.img-writed-by-big {
  border-radius: 50%;
  width: 120px;
}

.text-writed-by {
  padding-left: 30px;
  color: black;
  max-width: 600px;
}

.writed-by-section-big .writed-by-title-big {
  font-size: 27px;
  font-family: "Anton";
  padding-top: 8px;
  padding-bottom: 10px;
  color: black;
}

.writed-by-section-big .writed-by-descr-big {
  font-size: 18px;
}

/* ///////// WRITED BY SECTION END /////////////////////////////// */

.body-article {
  text-align: center;
}

.ground-col {
  background-color: #d3d3db;
}

.main-content-article {
  position: relative;
  display: flex;
  align-items: left;
}

.main-content-article .column-left {
  min-width: 20%;
  max-width: 20%;
}

.main-content-article .content-text {
  /* max-width: 80%; */
  max-width: 70%;
  color: #000;
  font-size: 20;
  overflow: hidden;
}

.main-content-article .content-image {
  max-width: 70%;
  color: #000;
  margin-top: -280px;
  font-size: 20;
  padding-bottom: 30px;
}

.main-content-article .content-text.main-image {
  overflow: visible;
}

.main-content-article .content-text p {
  font-size: 20px;
  line-height: 26px;
  /* font-weight: 200; */
  /* font-family: sans-serif; */
}

.main-content-article .content-text a {
  text-decoration: underline;
  /* font-weight: 200; */
  /* font-family: sans-serif; */
}

.main-content-article .content-text.Crypto a {
  color: #d5ecdc;
}
.main-content-article .content-text.Defi a {
  color: #f5d400;
}
.main-content-article .content-text.NFT a {
  color: #f18f01;
}

.main-content-article .Crypto h2 {
  color: #44cf6c;
}
.main-content-article .NFT h2 {
  color: #f18f01;
}
.main-content-article .Defi h2 {
  color: #f5d400;
}
.main-content-article .Crypto h1 {
  color: #44cf6c;
}
.main-content-article .Defi h1 {
  color: #f5d400;
}
.main-content-article .NFT h1 {
  color: #f18f01;
}

.main-content-article .content-text h2 {
  font-family: "Oswald", sans-serif;
  font-size: 30px;
  line-height: 23px;
  line-height: 40px;
}

.main-content-article .content-text h1 {
  font-family: "Oswald", sans-serif;
  font-size: 30px;
  line-height: 23px;
  line-height: 40px;
}

.main-content-article .content-text img {
  position: relative;
  display: block;
  width: 100%;
  margin: 35px 0;
  height: auto !important;
}

.main-content-article .content-text .main-image {
  width: calc(100% + 80px);
  margin: -290px -40px 40px;
  box-shadow: 2px 0 0 #000, 2px 2px 0 #000, 0 2px 0 #000, -2px 2px 0 #000,
    -2px 0 0 #000, -2px -2px 0 #000, 0 -2px 0 #000, 2px -2px 0 #000;
}

/* /////////// LIRE AUSSI /////////////////////////// */

/* /////////////////////////////////////////////////////////////// */

@media all and (max-width: 1200px) {
  .main-content-article .content-text .main-image {
    width: 100%;
    margin: 0 0 50px;
  }

  .article-body .head-article {
    margin-left: -9px;
  }

  .font-back,
  .font-back .wrapper,
  .font-back .logo-big {
    transform: skew(0);
  }

  .font-back .logo-big {
    padding-left: 0px;
    transform: skew(-20deg);
  }

  .main-content-article {
    flex-direction: column-reverse;
  }

  .main-content-article .column-left {
    min-width: 100%;
    max-width: 100%;
  }

  .writed-by-section {
    padding-top: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
    padding-bottom: 200px;
  }

  .img-writed-by-article {
    width: 40px;
    margin-right: 15px;
    padding-left: 0;
  }

  .writed-by-section .writed-by-title {
    padding-left: 0;
    padding-top: 0;
    color: black;
  }

  .font-back .intro {
    padding-bottom: 0;
  }

  .padding-20 {
    padding: 30px;
  }

  .tags-container {
    padding-top: 0;
    padding-left: 0;
  }

  .column-left .padding-20 {
    padding-top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .main-content-article .content-image {
    margin-top: -220px;
  }
}

@media all and (max-width: 960px) {
  .main-content-article .content-text {
    max-width: 100%;
  }
  .main-content-article .content-image {
    max-width: 100%;
  }
}

@media all and (max-width: 850px) {
  .tags-container-head {
    display: none;
  }
}

@media all and (max-width: 730px) {
  .tags-container-head {
    display: none;
  }
}

@media all and (max-width: 680px) {
  .font-back .title {
    font-size: 36px !important;
    padding-top: 5px;
  }

  .img-writed-by-big {
    border-radius: 50%;
    width: 100px;
    height: 100%;
    margin-top: 10px;
  }

  .writed-by-section-big {
    padding-top: 20px !important;
    padding-bottom: 0px !important;
  }

  .writed-by-section-big .writed-by-title-big {
    font-size: 20px;
    padding-top: 15px;
  }

  .writed-by-section-big .writed-by-descr-big {
    font-size: 15px;
    width: 90% !important;
  }

  .writed-by-section {
    padding-bottom: 180px !important;
  }

  .padding-20 .content-text .main-image {
    margin-top: -200px;
  }
}

@media all and (max-width: 451px) {
  .font-back .title {
    font-weight: 700;
    font-size: 27px;
    padding-top: 5px;
  }

  .font-back .padding-20 .writed-by-section {
    padding-bottom: 100px;
  }

  .font-back .intro {
    font-size: 20px;
    padding-top: 20px;
    font-family: "Oswald", sans-serif;
  }

  .img-writed-by-article {
    width: 30px;
  }

  .writed-by-section {
    padding-bottom: 110px !important;
  }

  .font-back .logo-big {
    font-family: "Anton";
    transform: skew(-20deg);
    font-size: 70px;
    padding-left: 10px;
    color: white;
    text-shadow: 2.5px 0 0 #000, 2.5px 2.5px 0 #000, 0 2.5px 0 #000,
      -2.5px 2.5px 0 #000, -2.5px 0 0 #000, -2.5px -2.5px 0 #000,
      0 -2.5px 0 #000, 2.5px -2.5px 0 #000;
  }
  .font-back .content-text .content-read-published {
    padding-top: 0px;
  }
  .font-back .content-text .published {
    font-size: 18px;
    padding-top: 0px;
  }

  .main-content-article .content-image {
    margin-top: -140px;
  }
}
