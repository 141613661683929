.content-footer {
  text-align: center;
  border-top: solid;
  margin-top: 100px;
  color: #fff;
  background-color: #6e78ff;
}

.content-footer.NFT {
  background-color: #f18f01 !important;
  color: #fff;
}
.content-footer.Crypto {
  background-color: #44cf6c !important;
  color: #fff;
}
.content-footer.Defi {
  background-color: #f5d400 !important;
  color: #fff;
}

.content-footer .content-rubrique-footer .rubrique-title {
  font-size: 28px;
  font-family: "Anton";
  padding-bottom: 17px;
  padding-top: 20px;
}

.content-footer .content-rubrique-footer .rubrique-element {
  font-size: 20px;
  font-family: "Oswald";
  display: inline;
  color: #fff;
}

.content-footer .content-rubrique-footer .rubrique-element:hover {
  color: #0a2463;
}

.content-footer .text-footer {
  font-size: 17px;
  font-weight: 600;
  padding-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
}

.social-img {
  padding-top: 60px;
  padding-bottom: 50px;
}

#document {
  fill: red;
}

.instagram-icon {
  margin-left: 5%;
  margin-right: 5%;
}

.instagram-icon:hover {
  opacity: 75%;
}

.text-footer .link-footer {
  color: #fff;
}

.text-footer .NFT .link-footer {
  color: #fff;
}
.text-footer .Crypto .link-footer {
  color: #fff;
}
.text-footer .Defi .link-footer {
  color: #fff;
}

.text-footer .link-footer:hover {
  color: #0a2463;
}

@media all and (max-width: 600px) {
}
