.content-main-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}
.main-header {
  position: relative;
  height: 85px;

  background-color: #39325f;
  color: #fff;
}

.fake-main-header {
  position: relative;
  height: 60px;
}

.main-header .website-logo {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  height: 50px;
  padding-top: 5px;
}

.main-header .website-title {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.7px;
  color: black;
  text-decoration: none;
}

.main-header .float-right > * {
  vertical-align: middle;
}

.website-logo {
  float: left;
  width: 50px;
}

.header .logo-title {
  text-decoration: none;
  font-size: 35px;
  padding-top: 14px;
  font-family: "Anton";
  font-style: italic;
  color: #6e78ff;
  text-shadow: 2px 0 0 #000, 2px 2px 0 #000, 0 2px 0 #000, -2px 2px 0 #000,
    -2px 0 0 #000, -2px -2px 0 #000, 0 -2px 0 #000, 2px -2px 0 #000;
}

.header .logo-title.span-x {
  color: #44cf6c;
}
.header .logo-title.span-y {
  color: #f5d400;
}
.header .logo-title.span-z {
  color: #f18f01;
}

.img-logo-header {
  padding-top: 8px;
  width: 8%;
}

.logo-pos {
  text-align: center;
}

.header {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.link-content {
  display: flex;
  cursor: pointer;
}

.link {
  text-decoration: none;
}

#article-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: #fff;
  transition: all 0.3s ease;
  transform: translateY(-100%);
}

#article-header.NFT {
  background-color: #b4a0e6 !important;
}
#article-header.Crypto {
  background-color: #9ee493 !important;
}
#article-header.Defi {
  background-color: #ffbf47 !important;
}

#article-header.shown {
  transform: translateY(0);
}

.img-3d {
  padding-top: 10px;
}

@media all and (max-width: 730px) {
  .header {
    justify-content: left;
  }
}
