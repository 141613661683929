.show-burger-nav {
  display: none;
}

.sidebar-content {
  text-align: left;
}
.sidebar-content .tag-sidebar {
  font-size: 30px;
  font-family: "Anton";
  font-style: italic;
  padding-top: 10px;
  padding-left: 50px;
}
.sidebar-content .tag-sidebar:hover {
  opacity: 60%;
}
.sidebar-content .tag-sidebar.NFT {
  color: #f18f01;
}
.sidebar-content .tag-sidebar.Crypto {
  color: #44cf6c;
}
.sidebar-content .tag-sidebar.Defi {
  color: #f5d400;
}
