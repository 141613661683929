.title-category .category-title {
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 30px;
  padding-right: 40px;
  margin-left: 17px;
  font-size: 47px;
  font-family: "Anton";
  color: black;
}

.title-category .category-title.NFT {
  color: #0a2463 !important;
}
.title-category .category-title.Defi {
  color: #0a2463 !important;
}
.title-category .category-title.Crypto {
  color: #0a2463 !important;
}

.title-category .parallelogram-title-petit {
  transform: skew(-20deg);
  display: flex;
}

.title-category .tag-selector-btn-list:hover {
  opacity: 0.5;
}

.title-category .category-subtitle {
  font-family: "Anton";
  font-size: 27px;
  padding-top: 24px;
  padding-right: 35px;
}

.title-category .category-subtitle.NFT {
  /* color: #0a2463 !important; */
  color: #fff !important;
}
.title-category .category-subtitle.Defi {
  /* color: #0a2463 !important; */
  color: #fff !important;
}
.title-category .category-subtitle.Crypto {
  /* color: #0a2463 !important; */
  color: #fff !important;
}

.title-category .link-see-more {
  z-index: 60;
}

@media all and (max-width: 825px) {
  .title-category {
    margin-left: -10px;
    margin-right: -2px;
    display: block;
  }

  .title-category .parallelogram-title-petit {
    transform: skew(0deg);
  }

  .category-subtitle {
    width: 100%;
    margin-right: 24px;
    padding-bottom: 20px;
  }

  .category-title {
    padding-top: 10px !important;
  }
}

@media all and (max-width: 650px) {
  .title-category .category-title {
    font-size: 35px;
    padding-right: 16px;
    padding-left: 10px;
  }

  .title-category .category-subtitle {
    font-size: 19px;
    padding-top: 24px;
    padding-right: 8px;
  }
}

@media all and (max-width: 450px) {
  .tag-selector {
    padding-top: 10px;
  }
}
