.article-box {
  height: 350px;
}

.article-box .img {
  width: 96%;
  padding-top: 8px;
}

.content-column-article-box {
  height: 430px;
}

.content-column-article-box .article-box .tag-and-time-article {
  padding-bottom: 30px;
}

.content-column-article-box
  .article-box
  .tag-and-time-article
  .tags-container1 {
  padding-top: 8px;
  text-decoration: none;
  color: black;
}

.content-column-article-box
  .article-box
  .tag-and-time-article
  .tags-container1
  .tag {
  float: left;
  padding-left: 10px;
  font-size: 20px;
  font-style: italic;
  font-family: "Anton";
}

.content-column-article-box
  .article-box
  .tag-and-time-article
  .tags-container1
  .tag:hover {
  opacity: 60%;
}

.content-column-article-box
  .article-box
  .tag-and-time-article
  .tags-container1
  .NFT {
  color: #f18f01;
}
.content-column-article-box
  .article-box
  .tag-and-time-article
  .tags-container1
  .Crypto {
  color: #44cf6c;
}
.content-column-article-box
  .article-box
  .tag-and-time-article
  .tags-container1
  .Defi {
  color: #f5d400;
}

.content-column-article-box .article-box .tag-and-time-article .read-time {
  float: right;
  font-size: 19px;
  font-style: italic;
  font-family: "Anton";
  padding-right: 13px;
  color: black;
}

/* //////////// TAG SELECTOR /////////////////////// */

.tag-selector {
  padding-top: 50px;
}

.tag-selector-btn-list {
  color: grey;
  width: auto;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 30px;
  font-family: "Anton";
  font-weight: 500;
  font-style: italic;
  cursor: pointer;
}

.tag-selector-btn-list.NFT {
  color: #f18f01;
}
.tag-selector-btn-list.Crypto {
  color: #44cf6c;
}
.tag-selector-btn-list.Defi {
  color: #f5d400;
}

.article-column {
  position: relative;
  float: left;
  box-sizing: border-box;
  width: 33%;
}

.spinner-content {
  padding-top: 100px;
  height: 250px;
  text-align: center;
}

@media all and (max-width: 1020px) {
  .article-column {
    position: relative;
    float: left;
    box-sizing: border-box;
    width: 50%;
  }

  .content-column-article-box {
    height: 950px;
  }
}

@media all and (max-width: 650px) {
  .article-column {
    position: relative;
    float: left;
    box-sizing: border-box;
    width: 100%;
  }

  .content-column-article-box {
    height: 1600px;
  }
}
