.head-article {
  /* overflow: hidden; */
}

.logo {
  font-family: "Anton";
  font-style: italic;
  /* transform: skew(-20deg); */
  font-size: 100px;
  color: #6e78ff;
  text-shadow: 2px 0 0 #000, 2px 2px 0 #000, 0 2px 0 #000, -2px 2px 0 #000,
    -2px 0 0 #000, -2px -2px 0 #000, 0 -2px 0 #000, 2px -2px 0 #000;
}

.logo.span-x {
  color: #44cf6c;
}
.logo.span-y {
  color: #f5d400;
}
.logo.span-z {
  color: #f18f01;
}

.header-flex-content {
  display: flex;
}

.logo .title-petit {
  font-size: 40px;
  padding-top: 72px;
  font-family: "Anton";
}

.shown-burger {
  display: none;
}

.logo-koon-3d {
  transform: skew(20deg);
}

.logo-koon-3d-small {
  transform: skew(20deg);

  display: none;
}

.rubrique-home {
  text-align: center;
  width: 100%;
  padding-top: 72px;
  padding-left: 10%;
  display: flex;
}

.rubrique-home .link-rubrique {
  font-size: 30px;
  font-style: italic;
  font-family: "Anton";
  color: #1f1f1f;
  display: inline;
  text-decoration: none;
  margin-left: 35px;
}

.rubrique-home .link-rubrique.NFT:hover {
  color: #f18f01;
}
.rubrique-home .link-rubrique.Defi:hover {
  color: #f5d400;
}
.rubrique-home .link-rubrique.Crypto:hover {
  color: #44cf6c;
}

@media all and (max-width: 930px) {
  .rubrique-home .link-rubrique {
    display: none;
  }
}

@media all and (max-width: 600px) {
  .logo {
    font-size: 80px;
  }

  .logo .title-petit {
    font-size: 30sx;
    padding-top: 40px;
    font-family: "Anton";
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media all and (max-width: 500px) {
  .logo {
    font-size: 45px;
    padding-top: 5px;
  }

  .logo .title-petit {
    font-size: 30px;
    padding-top: 36px;
    font-family: "Anton";
    padding-left: 5px;
    padding-right: 5px;
  }
}
