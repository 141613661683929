.article-box {
  height: 350px;
}

.content-column-article-box-admin .article-box .img {
  object-fit: cover;
  width: 98%;
  height: 250px;
}

.content-column-article-box-admin .article-box .tag-and-time-article {
  padding-bottom: 30px;
}

.content-column-article-box-admin
  .article-box
  .tag-and-time-article
  .tags-container1 {
  padding-top: 8px;
  text-decoration: none;
  color: black;
}

.content-column-article-box-admin .article-box .img-writed-by {
  border-radius: 50%;
  width: 40px;
}

.content-column-article-box-admin
  .article-box
  .tag-and-time-article
  .tags-container1
  .tag {
  float: left;
  padding-left: 10px;
  font-size: 20px;
  font-style: italic;
  font-family: "Anton";
}

.content-column-article-box-admin
  .article-box
  .tag-and-time-article
  .tags-container1
  .NFT {
  color: #b5446e;
}
.content-column-article-box-admin
  .article-box
  .tag-and-time-article
  .tags-container1
  .Crypto {
  color: #3993dd;
}
.content-column-article-box-admin
  .article-box
  .tag-and-time-article
  .tags-container1
  .Defi {
  color: #eb3e0a;
}

.content-column-article-box-admin
  .article-box
  .tag-and-time-article
  .read-time {
  float: right;
  font-size: 19px;
  font-style: italic;
  font-family: "Anton";
  padding-right: 13px;
  color: black;
}

/* //////////// TAG SELECTOR /////////////////////// */

.tag-selector-btn {
  color: black;
  width: auto;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
}

.tag-selector-btn.true {
  color: red;
}
