.content {
  color: black;
}

.create-btn {
  padding: 10px 15px 10px 15px;
  font-size: 20px;
  font-weight: 600;
  margin-top: 20px;
  border-radius: 16px;
  background-color: #de5225;
  color: white;
}

.input-wallet {
  border-radius: 5px;
  box-shadow: none;
  background-color: #fff;
  border: 1px solid #c8c8c8;
  padding: 10px;
  outline: 0;
  position: relative;
  width: 300px;
  margin-bottom: 20px;
}

.select-input {
  width: 300px;
  padding-bottom: 130px;
}

/* //////////////////// PREVIEW ARTICLE ////////////////////// */

.preview-article .main-content-article {
  position: relative;
  display: flex;
  align-items: left;
}

.preview-article .main-content-article .column-left {
  min-width: 20%;
  max-width: 20%;
}

.preview-article .main-content-article .content-text {
  /* max-width: 80%; */
  max-width: 70%;
  color: #000;
  font-size: 20;
}

.preview-article .main-content-article .content-text p {
  font-size: 20px;
  line-height: 26px;
  /* font-weight: 200; */
  /* font-family: sans-serif; */
}

.preview-article .main-content-article .content-text a {
  text-decoration: underline;
  /* font-weight: 200; */
  /* font-family: sans-serif; */
}

.preview-article .main-content-article .content-text.Crypto a {
  color: #3993dd;
}
.preview-article .main-content-article .content-text.Defi a {
  color: #eb3e0a;
}
.preview-article .main-content-article .content-text.NFT a {
  color: #b5446e;
}

.preview-article .main-content-article .Crypto h2 {
  color: #3993dd;
}
.preview-article .main-content-article .NFT h2 {
  color: #b5446e;
  font-weight: 900;
}
.preview-article .main-content-article .Defi h2 {
  color: #ffc1cf;
}
.preview-article .main-content-article .Crypto h1 {
  color: #3993dd;
}
.preview-article .main-content-article .Defi h1 {
  color: #ffc1cf;
}
.preview-article .main-content-article .NFT h1 {
  color: #b5446e;
}

.preview-article .main-content-article .content-text h2 {
  font-size: 30px;
  line-height: 23px;
  line-height: 40px;
}

.preview-article .main-content-article .content-text h1 {
  font-size: 30px;
  line-height: 23px;
  line-height: 40px;
}

.preview-article .main-content-article .content-text img {
  position: relative;
  display: block;
  width: 100%;
  margin: 35px 0;
  height: auto !important;
}
