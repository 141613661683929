.home-title {
  padding-top: 20px;
  font-size: 30px;
  font-weight: 900;
  color: black;
  font-family: "Anton";
  font-style: italic;
}

.big-box-actu .img-content .img {
  width: 98%;
}

.column-big-news {
  position: relative;
  float: left;
  box-sizing: border-box;
  width: 60%;
}

.big-box-actu .post-title {
  color: black;
  font-size: 30px;
  padding-right: 20px;
  padding-top: 32px;
  text-align: left;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
}

.big-box-actu .post-intro {
  color: black;
  font-size: 23px;
  padding-right: 20px;
  padding-top: 5px;
  text-align: left;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
}

.big-box-actu .tags-container-banner {
  text-decoration: none;
  color: black;
  font-size: 23px;
  font-style: italic;
  margin-left: -5px;
}

.big-box-actu .time-read {
  float: right;
  font-size: 21px;
  font-family: "Anton";
  padding-right: 26px;
}

.big-box-actu .tags-container-banner .tag {
  font-family: "Anton" !important;
  float: left;
  font-size: 22px;
}

.big-box-actu .tags-container-banner .tag:hover {
  opacity: 60%;
}

.big-box-actu .tags-container-banner .tag:last-child {
  padding-left: 8px;
}

.big-box-actu .tags-container-banner .NFT {
  color: #f18f01;
}

.big-box-actu .tags-container-banner .Crypto {
  color: #44cf6c;
}

.big-box-actu .tags-container-banner .Defi {
  color: #f5d400;
}

/* SMALL BOX */
.small-box-actu {
  margin-left: 25px;
  background-color: white;
  height: 140px;
}

/* .small-box-actu:hover {
  box-shadow: 12px 12px 2px 1px rgba(0, 0, 255, 0.2);
} */

/* /////////////////// SIDE BOX ACTU //////////////////////// */

.side-box-actu {
  display: flex;
  height: 190px;
}

.side-box-actu .img-side-news {
  width: 250px;
  height: 180px !important;
}

.side-box-actu .content-tag-read-time .time-read {
  float: right;
  font-family: "Anton";
  color: #000;
  font-style: italic;
  padding-right: 9px;
  padding-top: 1px;
}

.side-box-actu .tags-container-banner .tag {
  font-family: "Anton" !important;
  padding-left: 10px;
  font-size: 17px;
}

.side-box-actu .tags-container-banner .tag:hover {
  opacity: 60%;
}

.side-box-actu .tags-container-banner .tag:last-child {
  padding-left: 8px;
}

.side-box-actu .tags-container-banner .tag {
  font-family: "Anton" !important;
}

.side-box-actu .tags-container-banner .NFT {
  color: #f18f01;
}

.side-box-actu .tags-container-banner .Crypto {
  color: #44cf6c;
}

.side-box-actu .tags-container-banner .Defi {
  color: #f5d400;
}

.side-box-actu .title-side-box {
  font-size: 19px;
  color: black;
  font-weight: 800;
  margin-top: 32px;
  padding-left: 10px;
  padding-bottom: 5px;
  font-family: "Oswald", sans-serif;
  height: 90px;
  /*  hide texte  */
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.side-box-actu .tag {
  font-size: 17px;
  float: left;
  font-style: italic;
}

.side-box-actu .content-writed-by {
  display: flex;
  padding-left: 10px;
  padding-top: 25px !important;
}

.side-box-actu .content-writed-by .img-writed-by {
  width: 25px;
  border-radius: 50%;
}

.side-box-actu .content-writed-by .writeBy {
  font-family: "Anton";
  font-size: 14.5px;
  color: #000;
  padding-right: 10px;
  padding-top: 5px;
}

@media all and (max-width: 900px) {
  .all-side-box {
    display: none;
  }
  .img-content {
    text-align: center;
  }
}
