.title-category .category-title {
  padding-left: 30px;
  padding-right: 40px;
  margin-left: 17px;
  font-size: 47px;
  font-family: "Anton";
  color: #1f1f1f;
}

.title-category .category-title.NFT .Defi .Crypto .Info {
  color: #0a2463 !important;
}

/* .title-category .parallelogram-title-petit {
  transform: skew(-20deg);
  display: flex;
} */

.parallelogram-title-petit .NFT {
  background-color: #f18f01 !important;
  box-shadow: 12px 10px 0px 1px #0a2463;
}
.parallelogram-title-petit .Defi {
  background-color: #f5d400 !important;
  box-shadow: 12px 10px 0px 1px #0a2463;
}
.parallelogram-title-petit .Crypto {
  background-color: #44cf6c !important;
  box-shadow: 12px 10px 0px 1px #0a2463;
}
.parallelogram-title-petit .News {
  background-color: #e63946 !important;
  box-shadow: 12px 10px 0px 1px #0a2463;
}

.title-category .category-subtitle {
  font-family: "Anton";
  font-size: 27px;
  /* padding-top: 24px; */
  padding-right: 35px;
}

.title-category .category-subtitle.NFT {
  /* color: #0a2463 !important; */
  color: #fff !important;
}
.title-category .category-subtitle.Defi {
  /* color: #0a2463 !important; */
  color: #fff !important;
}
.title-category .category-subtitle.Crypto {
  /* color: #0a2463 !important; */
  color: #fff !important;
}

.title-category .link-see-more {
  z-index: 2;
}

.title-category .see-more {
  display: block;
  position: relative;
  float: right;
  font-family: "Anton";
  font-size: 22px;
  padding-right: 30px;
  margin-bottom: -22px;
  margin-top: -17px;
  z-index: 2 !important;
  text-decoration: none;
  /* text-shadow: 1px 0 0 #000, 1px 1px 0 #000, 0 1px 0 #000, -1px 1px 0 #000,
    -1px 0 0 #000, -1px -1px 0 #000, 0 -1px 0 #d60, 1px -1px 0 #000; */
}

.title-category .see-more:hover {
  opacity: 0.8;
}

.title-category .see-more.NFT {
  color: #0a2463 !important;
}
.title-category .see-more.Defi {
  color: #0a2463 !important;
}
.title-category .see-more.Crypto {
  color: #0a2463 !important;
}

@media all and (max-width: 825px) {
  .title-category {
    margin-left: -10px;
    margin-right: -2px;
    display: block;
  }
  .title-category .category-title {
    padding-top: 5px !important;
  }
  .title-category .category-subtitle {
    padding-bottom: 0px !important;
    width: 100%;
    margin-right: 24px;
  }

  .title-category .parallelogram-title-petit {
    transform: skew(0deg);
  }

  .title-category .see-more {
    display: none;
  }
}

@media all and (max-width: 650px) {
  .title-category .category-title {
    font-size: 35px;
    padding-right: 16px;
    padding-left: 10px;
  }

  .title-category .category-subtitle {
    font-size: 19px;
    padding-top: 19px !important;
    padding-right: 8px;
  }
}

@media all and (max-width: 465px) {
  .title-category .category-title {
    font-size: 25px !important;
    padding-right: 16px;
    padding-left: 10px;
    padding-top: 4px !important;
  }

  .title-category .category-subtitle {
    font-size: 15px !important;
    padding-right: 8px;
    padding-top: 13px !important;
  }
}
