.article-box {
  height: 350px;
  font-family: "Anton";
}

.article-box .img {
  object-fit: cover;
  width: 97%;
  height: 250px;
}

.article-box:hover {
  opacity: 0.8;
}

.article-box .tag-and-time-article-box {
  padding-bottom: 30px;
}

.article-box .tag-and-time-article-box .tags-container {
  padding-top: 8px;
  text-decoration: none;
  color: black;
}

.article-box .tag-and-time-article-box .tags-container .tag-display {
  float: left;
  padding-left: 10px;
  font-size: 20px;
  text-decoration: none;
}

.article-box .tag-and-time-article-box .tags-container .tag-display .tag {
  font-family: "Anton";
}

.article-box .tag-and-time-article-box .tags-container .tag-display:hover {
  opacity: 0.7;
}

.article-box .tag-and-time-article-box .tags-container .NFT {
  color: #f18f01;
}
.article-box .tag-and-time-article-box .tags-container .Crypto {
  color: #44cf6c;
}
.article-box .tag-and-time-article-box .tags-container .Defi {
  color: #f5d400;
}

.article-box .tag-and-time-article-box .read-time {
  float: right;
  font-size: 19px;
  font-style: italic;
  padding-right: 13px;
  font-family: "Anton";
  color: black;
}

.article-box .title {
  font-size: 24px;
  font-weight: 700;
  color: black;
  float: left;
  padding-left: 9px;
  font-family: "Oswald", sans-serif;
  text-align: left;
  padding-right: 20px;
  /*  hide texte  after 3 lines */
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.article-box .content-writed-by {
  padding-top: 100px;
  padding-right: 10px;
}

.article-box .writeBy {
  font-size: 17px;
  padding-right: 13px;
  padding-top: 9px;
  float: right;
  color: #000;
}

.article-box .content-writed-by .img-writed-by-article-box {
  width: 35px;
  float: right;
  border-radius: 50%;
}

.article-box .writeBy {
  font-size: 17px;
  padding-right: 13px;
  padding-top: 9px;
  float: right;
  font-family: "Anton";
}

@media all and (max-width: 500px) {
  .article-box .title {
    font-size: 20px;
  }
}
