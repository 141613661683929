.website-loader {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 30px;
  height: 30px;
  animation: loader-turn 1s linear infinite;
}
.website-loader.size-20 {
  width: 20px;
  height: 20px;
}
.text-align-left .website-loader {
  margin: 0;
}

@keyframes loader-turn {
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(720deg);
  }
}

.website-loader .loader__value {
  animation: loader-stroke 6s linear infinite;
  fill: none;
  stroke-dasharray: 63;
  stroke-dashoffset: 63;
  stroke-linecap: round;
  stroke-width: 3;
}

.website-loader .loader__value:nth-child(2) {
  animation-delay: 1s;
}

.website-loader .loader__value:nth-child(3) {
  animation-delay: 2s;
}

.website-loader .loader__value:nth-child(4) {
  animation-delay: 3s;
}

.website-loader .loader__value:nth-child(5) {
  animation-delay: 4s;
}

.website-loader .loader__value:nth-child(6) {
  animation-delay: 5s;
}

@keyframes loader-stroke {
  8.3333333333% {
    stroke-dashoffset: 0;
  }
  16.6666666667%,
  100% {
    stroke-dashoffset: 63;
  }
}

.website-loader.loader-button {
  width: 16px;
  height: 16px;
  margin: 9px 20px;
}

.button.width-170 .website-loader.loader-button {
  margin: 9px auto;
}
.loader-position-right .content-children {
  vertical-align: top;
  line-height: 32px;
}

.loader-position-right .website-loader.loader-button,
.loader-position-left .website-loader.loader-button {
  margin: 0;
  display: inline-block;
  vertical-align: middle;
  top: -2px;
}
.loader-position-right .website-loader.loader-button {
  margin-left: 8px;
}
.loader-position-left .website-loader.loader-button {
  margin-right: 8px;
}
